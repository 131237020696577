.royalSlider {
    width: 50%;
    // height: 400px;
    position: relative;
    direction: ltr;

    .rsBullets {
        position: relative;
        z-index: 35;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        // background: #000;
        background: transparent;
        text-align: center;
        line-height: 8px;
        overflow: hidden;
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        padding: 6px 11px 6px;

        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #869385;
        }
    }

    .rsSlide {
        img {
            opacity: 0.45 !important;
            transition: all 0.3s ease-out;
            transform: scale(1);
        }
    }

    .rsActiveSlide {
        img {
            opacity: 1 !important;
            transform: scale(1);
        }
    }
}

.rsDefault {
    &.rsHor {
        .rsArrow.rsArrow {
            top:50%; transform: translateY(-50%);
            width: 60px; height: 60px;
            background-color: transparent;
            background-position: top left;
            background-repeat: no-repeat;

            .rsArrowIcn.rsArrowIcn {
                position: static;
                width: 100%; height: 100%;
                margin:0;
                background-color: #2d2d2c;
                border-radius:0;
            }

        }
        .rsArrowLeft {
            .rsArrowIcn.rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowLeft.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 13px;

            }
        }
        .rsArrowRight {
            .rsArrowIcn.rsArrowIcn{
                background-image: url('/img/sliders/rsSlider_arrowRight.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 13px;
            }
        }
    }

    .rsDefault .rsArrowDisabled .rsArrowIcn {
        opacity: 0;
    }


    .rsOverflow {
        background-color: transparent;
    }

    .rsBullet.rsBullet {
        span {
            width: 10px; height: 10px;
        }
    }
}

.rsDefault,
.rsSlide {
    background-color: @core-color__default;
}




// mobile and tablet
@media (max-width: 1199px) {
  .rsDefault .rsBullet.rsBullet {
    span {
      width: 6px; height: 6px;
    }
  }
}
