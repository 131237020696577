@import (less) "normalize.css";
@import (less) "../node_modules/bootstrap/dist/css/bootstrap.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";
@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";

@import (less) "fonts.less";
@import (less) "variables.less";
@import (less) "menu-horizontal.less";
@import (less) "slider.less";
@import (less) "dropkick.less";
@import (less) "form.less";
@import (less) "form-slider.less";
@import (less) "home.less";
@import (less) "thankyou.less";

// * {
// 	border: 1px solid red;
// }

.home {
	overflow: overlay;
}