/* home.less
 *
 *
 */




/* Variables ******************************************************************/

@padding: 20px;
@padding-large: 50px;
@font-size-large: 18px;

.client-highlight {
  color: #ff00ff !important;
}


/* Shared *********************************************************************/

.bgi {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.copy {
  margin-bottom: @padding-large;
  color: @text-green;
  font-size: 16px;
  line-height: 1.6;
  width: 90%;
  span {
    color: @text-black;
  }

  @media (min-width: 1199px) {
      font-size: @font-size-large;
  }
}

/* Waypoint Hack *********************************************************************/

.waypoint-hack {
  height: 10px;
}

.green-hack {
  .waypoint-hack;
  background-color: @green;
}

.grey-hack {
  .waypoint-hack;
  background-color: @dark-grey;
}

.white-hack {
  .waypoint-hack;
  background-color: @grey;
}

/* Styles *********************************************************************/

body {
  color: @text-black;
  font-family: @font-family-base;
}

/* iphone changing the color of phone numbers */
a[href^="tel"] {
  color: inherit;
}

.number-link {
  color: @text-white;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    color: @text-white;
  }
}

.contact {
  color: @text-white;
}

.intro {
  background-color: @grey;

  &__logo {
    box-sizing: border-box;
    padding: 25px 30px 30px 30px;
    width: 50%;
  }

  &__image {
    .bgi;
    position: relative;
    min-height: 50vh;
  }

  &__details {
    margin-top: @spacing;
    width: 100%; min-width: 100%;
    height: 20vh;
    background-color: @green;
    font-size: @font-size-large;
  }

  &__tagline {
    float: left;
    margin: @spacing-half 0 0 @spacing-half;
  }

  &__hours {
    float: right;
    margin: @spacing-half @spacing-half 0 0;
    p {
      margin: 0;
      font-size: @font-size-large;
    }
  }

  &__tagline {

    span {
      color: @text-green;
    }
  }

  &__price {
    display:none;
  }

  &__hours {
    span {
      color: @text-green;
    }
  }

  @media (min-width: 1199px) {

    &__content {
      width:100%;
      margin-top: 60px; //Offset Menu
    }

    &__title {
      height: 25vh;
      width: @rs-width;
      display: flex; align-items: flex-end; justify-content: flex-end;
    }

    &__details {
      font-size: @large-heading;
    }

    &__logo {
      width: 263px;
      padding: 30px 50px 30px 0;
    }

    &__tagline {
      margin-left: @spacing;
      float: left;
    }

    &__hours {
      margin-right: @spacing;
      float: right;
      p {
        font-size: @large-copy;
      }
    }

    &__image {
      height: 75vh;
      //width: 100vw;
      width: 100%;
    }

    &__price {
      display:block;
      position: relative; top:-20.5vh;
    }
  }

}

.btn-register {
  z-index: 9999;
  width: 50%;
  float:right;
  padding: 10px 20px 10px 10px;
  background-color: @dark-green;
  border: none; outline: none;
  font-size: 12px; color: @text-white; letter-spacing: 2px;
  text-align: right; text-transform: uppercase;


  img {
    display: inline-block;
    width: 7px;
    margin-left: 5px;
    vertical-align: top;
  }

  @media (min-width: 1199px) {
    width: auto;
    padding: 15px 50px 15px 20px;
    font-size: 14px; text-align: left;
    letter-spacing: 4px;

    img {
      width: 9px;
      margin-left: 25px;
    }

 }
}

.btn-scroll {
  position: absolute; bottom: 0;
  width:100%;
  padding-top: 15px; padding-bottom:15px;
  outline: 0; border:none;
  text-transform: uppercase ;color:#fff;
  font-size: 12px; letter-spacing: 2px; text-align:center;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);

  span {  display:block;  }
  img { width: 25px; }

  @media (min-width: 1199px) {
    font-size: 14px;
    letter-spacing: 4px;
    img { width: 40px; }
  }
}


.intro-carousel {
  .bgi;
    display:block;
    width: 100%;
    height: 50vh;

    .rsSlide {
      width: 100% !important; height: 100% !important;
    }

  @media (min-width: 1199px) {
    .bgi;
    display:block;
    width: 100%;
    height: 75vh;

    .rsSlide {
      width: 100% !important; height: 100% !important;
    }
  }
}

.intro-carousel-slider {
  width: 100% !important; height: 100% !important;
  background-color: transparent;


  .rsContent {
    .bgi;
  }

  .rsBullets {
    position: absolute;
    bottom: 10px;
  }
}


.cascade {

  background-color: @grey;

  &__image {
    .bgi;
    min-height: 20vh;
  }

  &__copy-container {
    padding: 15px @padding @padding @padding;
  }

  &__copy {
    .copy;
    margin-top: @spacing-large;
    margin-bottom: 0;
  }

  &__tagline {
    span {
      color: @text-green;
    }
  }

  &__carousel {
    min-height: 50vh;
    background-color: @dark-grey;
  }

  @media (min-width: 1199px) {

    min-height: 50vh;

    &__image {
      order: -1;
      width: 33.333333%;
      min-width: 33.333333%;
      flex-direction: column;
    }

    &__copy-container {
      padding: 40px @padding-large 40px @padding-large;
    }

    &__copy {
      margin-top: 0;
      width: 50%;
      float: right;
    }

    &__tagline {
      font-size: @font-size-large;
      width: 50%;
      float: left;
      span {
        display: none;
      }
    }

  }

}

.residences {
  padding: @padding;
  background-color: @green;
  min-height: 0;

  &__copy {
    .copy;
    float: left;
  }

  &__tagline {

  }

  &__carousel {
    min-height: 25vh;
    .bgi;
  }

  @media (min-width: 1199px) {
    padding:0;
    min-height: 50vh;
    &__copy {
      width: 50%;
      padding: 40px @padding-large @padding-large @padding-large;
      float: right;
    }
  }
}


.lifestyle {
  padding: 15px @padding @padding @padding;
  background-color: @dark-grey;

  &__copy {
    .copy;
    margin-bottom: 80px;
    margin-top: @spacing-large;
    span {
      color: #fff;
    }
  }

  &__carousel {
    min-height: 50vh;
    .bgi;
  }

  @media (min-width: 1199px) {
    width: 100%;
    height: 75vh;
    padding:0;
    &__copy {
      width: 50%;
      padding: @padding-large;
      margin-bottom: 0;
      margin-top: 0;
      font-size: @font-size-large;
      float: right;
      p {
        max-width: 90%;
      }
    }

    &__carousel {
      width: 100%;
      min-height: 100vh;
    }
  }
}

.team-carousel {
  //width: 50vw;
  width: 50%;
}

#waypoint_team {
  height: 405px;
  float: left;
}

.team__carousel-slider {
  width: 100% !important; height: 100% !important;
  background-color: @green;

  .rsContent {
    .bgi;
  }

  .rsGCaption.rsGCaption {
      //left: 20px; bottom: -13%;
      //padding-left: 0;
      background: transparent;
      color: @text-white;
  }

  .rsBullets.rsBullets {
      position: absolute;
      left: 50%; transform: translateX(-50%);
      width: 180px; bottom: 10px;
      background: transparent;
  }

  @media (min-width: 1199px) {
    .rsBullets.rsBullets {
      left: 52%;
      position: absolute;
      bottom: 10px;
    }

    .rsGCaption.rsGCaption {
      //bottom: -110px;
      //padding: 0 0 30px 50px;
      font-size: 16px;
    }

    .rsBullet.rsBullet span {
      background-color: @dark-green;
      bottom:0;
    }

    .rsBullet.rsBullet.rsNavSelected span {
      background-color: #fff;
    }
  }

}


.map-wrapper {
  .map {
    min-height: 50vh;
    background-color: #fff;
  }

  #google-map-canvas {
    height: 100%;
    min-height: 50vh;
  }
}

@media (min-width: 1199px) {
  .map-wrapper {
    min-height: 50vh;
    padding:0;
    .map {
      width: 100%;
    }
    
    #google-map-canvas {
      min-height: 75vh;
    }
  }
}


.location {
  padding: @padding;
  background-color: @green;

  &__copy {
    .copy;
  }

  @media (min-width: 1199px) {
    min-height: 60vh;
    padding:0;

    &__copy {
      width: 50%;
      padding: 40px 50px 0px 50px;
      margin-bottom: 0;
      font-size: @font-size-large;
      float: right;
      p {
        max-width: 90%;
      }
    }
  }
}

.team {
  padding: 15px @padding @padding @padding;
  background-color: @dark-grey;
  width: 50vw;
  float: right;

  &__copy {
    .copy;
    margin-bottom: 80px;
    margin-top: @spacing-large;
    span {
      color: #fff;
    }
  }

  &__carousel {
    min-height: 50vh;
    .bgi;
  }

  @media (min-width: 1199px) {
    //height: 500px;
    padding:0;
    //width: 50vw;
    width: 50%;
    float: right;

    &__copy {
      //width: 50%;
      //padding: @padding-large;
      padding: 20px;
      padding-top: 50px;
      margin-bottom: 0;
      margin-top: 0;
      font-size: @font-size-large;
      float: right;
      p {
        max-width: 90%;
      }
    }

    &__carousel {
      width: 100%;
      min-height: 100vh;
    }
  }
}

.lifestyle__carousel-slider {
  width: 100% !important; height: 100% !important;
  min-height: 50vh;
  background-color: transparent;

  .rsContent {
    .bgi;
  }

  .rsGCaption.rsGCaption {
      left: 20px; bottom: -13%;
      padding-left: 0;
      background: transparent;
      color: @text-white;
  }

  .rsBullets.rsBullets {
      position: absolute;
      left: 50%; transform: translateX(-50%);
      width: 180px; bottom: 10px;
      background: transparent;
  }

  @media (min-width: 1199px) {
    .rsBullets.rsBullets {
      left: 52%;
      position: absolute;
      bottom: 10px;
    }

    .rsGCaption.rsGCaption {
      bottom: -110px;
      padding: 0 0 30px 50px;
      font-size: 16px;
    }

    .rsBullet.rsBullet span {
      background-color: @dark-green;
      bottom:0;
    }

    .rsBullet.rsBullet.rsNavSelected span {
      background-color: #fff;
    }
  }

}

.footer {
  p {
    margin: 0;
  }
}

.contact__footer {
  font-size: @copy-size;
  width: 100%;
  height: 150px;
  padding: @spacing;
  background-color: @dark-green;
}

.contact__address {
  width: 50%;
  float: left;
}

.contact__agent {
  width: 50%;
  // float: right;
}

.footer-ls, .footer-rs {
  width: 50%;
}

.footer-ls {
  float: left;
}

.footer-rs {
  width: 20%;
  float: right;
  text-align: center;

  p {
    padding-top: 17px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.up-arrow-wrapper {
  display: block;
  margin: 0 auto;
  width: 30px;
}

.contact-white {
  color: @text-white;
  a {
    padding-bottom: 2px;
    &:hover {
      color: @text-white;
      text-decoration: none;
      border-bottom: 1px solid @text-white;
    }
  }
}

@media (max-width: 991px)  {
  .contact__footer {
    width: 100%;
    height: 300px;;
    padding: @spacing;
    background-color: @dark-green;
    position: relative;
  }

  .footer-ls {
    float: right;
    //width: 50%;
    width: 60%;
    position: absolute;
    bottom: 70px;
    right: 0;
  }

  .footer-rs {
    float: left;
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 40%;
    p {
      font-size: 13px;
    }
  }

  .contact__address, .contact__agent {
    width: 100%;
  }
  .contact__agent {
    margin-top: 10px;
  }
}

@media (min-width: 1199px){}
@media (min-width: 767px){}
@media (min-width: 767px){}

@media (max-width: 767px) {
  .team-carousel {
    width: 100vw;
  }

  .team {
    height: auto;
    width: 100vw;
  }

  .team__carousel-slider {
    .rsGCaption.rsGCaption {
      bottom: -50px;
    }
  }

  .intro__details {
    height: 23vh;
  }

  .intro__hours {
    margin: 25px 0 0 0;
    p{
      font-size: 16px;
    }
  }

  .intro__tagline {
    font-size: 16px;
  }
}
