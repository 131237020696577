@font-face {
    font-family: 'Graphik-light';
    src: url('../fonts/Graphik-Extralight.woff2') format('woff2'),
        url('../fonts/Graphik-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-medium';
    src: url('../fonts/Graphik-Medium.woff2') format('woff2'),
        url('../fonts/Graphik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

