.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 20px 0;
	text-align: center;
	float: left;
	margin-right: 40px;
	li {
		display: inline-block;
		list-style: none;
    padding: 0;
		margin-right: 40px;
		font-size: @copy-size;
		//text-transform: uppercase;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			color: @text-black;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: none;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

.ham-menu-container, .close-menu {
	display: none;
}

.menu-wrapper {
	width:100%;
	position:fixed;
	top:0;
	left:0;
	background-color: @grey;
	z-index: 200;
}
.logo-wrapper {
	float: left;
	width: 200px;
	padding-left: @spacing;
	margin-top: 1%;
	//transform: translate(0%, 50%);
}
.nav-wrapper {
	float: right;
}
.menu-wrapper, .btn-register {
	height: 60px;
}

.agent-contact {
	display: none;
}

.btn-reg-fixed {
	display: none;
}


.menu-link-active {
	border-bottom: 1px solid @text-black;
	a {
		color: red;
	}
}

@media (max-width: 991px)  {  
	
	.menu-wrapper {
		height: @spacing;
		position: absolute;
	}



	.btn-reg {
		display: block;
		background-color: @dark-grey;
		color: @text-white;
		height: 35px;
		padding: 10px;
		float: none;
		margin-left: @spacing-half;
		text-transform: uppercase;
		text-align: center;
    z-index: 10;
	  width: 50%;
	  border: none; 
	  outline: none;
	  text-align: left; 
	  text-transform: uppercase;
	  font-size: 12px;
	  letter-spacing: 2px;
	}

	.btn-reg-fixed {
		.btn-reg;
		width: 50%;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px 10px 10px 20px;
    img {
    	display: inline-block;
	    width: 7px;
	    margin-left: 15px;
	    vertical-align: top;
    }

    &:hover {
			text-decoration: none;
			color: @text-white;
    }
    &:active {
			text-decoration: none;
			color: @text-white;
    }
	}

	.nav-wrapper {
		min-height: 100vh;
		right: -100vw;
		width: 200px;
		background-color: @green;
		transition: all 0.7s ease;
		position: fixed;
		z-index: 100;
		.btn-register {
			.btn-reg;
			width: 80%;
		}
	}

	.ham-menu-container {
		display: block;
		width: 50px;
    position: fixed;
    top: 0;
    right: 0;
	}

	.nav-wrapper__active {
		right: 0;
	}

	.menu-horizontal { 
		li {
			width: 100%;
			text-align: left;
			padding-left: 10px;			
		}
	}

	.menu-wrapper {
		z-index: 200;
	}

	.close-menu {
		display: block;
		width: 50px;
		float: right;
	}

	.menu-horizontal {
		padding: 0px @spacing-half;
		li {
			padding-left: 0;
		}
	}

	.menu-scroller {
		line-height: 35px;
	}
	
	.agent-contact {
		padding: 0 @spacing-half;
		display: block;
		position: fixed;
		bottom: 0;
		p {
			font-size: 12px;
		}
	}

	.logo-wrapper {
		padding-left: @spacing-half;
		width: 150px;
	}

}