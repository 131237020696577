.thankyou__wrapper {
	background-color: @green;
	height: 100vh;
	display: flex;
  align-items: center;
  justify-content: center;
}

.thankyou__msg {
	color: @text-black;
	text-align: center;
	width: 50%;
}

@media (max-width: 767px) {
	.thankyou__msg {
		width: 100%;
	}	
}