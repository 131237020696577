@labelCol: @text-black;

@inputCol: @text-white;
@backgroundCol: transparent;

@formFontSize: 1.2em;

@submitCol: @text-black;
@submitBg: transparent;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
 
.error {
  color: #b10502 !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 55px;
  width: 100%;
  border: 0;
  font-size: @formFontSize;
  font-family: @font-family-base;
  font-weight: 300;
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  border-bottom: 2px solid @borderCol;
  padding: @form-input-spacing 0;
  text-align: left;
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 4px;
  height: 55px;
  box-sizing: border-box;
  background: url("../img/core/down-arrow-white.svg") 97% 50% no-repeat @backgroundCol;
  background-size: 18px;
}

.custom-submit {
  color:@submitCol;
  box-shadow: none;
  font-size: @formFontSize;
  background-color: @green;
  text-align: center;
  transition: 0.5s ease;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
     // background-color: #28baaf;
  }
}

.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: bold;
}
::placeholder {
  color: #FFFFFF;
}
option {
  color: #000000 !important;
}

.customSelectInner {
    width: 100% !important;
    padding-top: 13px;
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 50%;
  height: 50px;
}
.form-field-right-half {
  float: right;

}
.form-field-full {
  width: 100%;
}
.form-field{
  width: 33%;
  float: left;
  padding-right: 10px;
}
.form-field-large {
  .form-field;
  width: 66%;
}
.form-slider {
  padding: @spacing;
}
.form-inputs {
  float: left;
  width: 70%;
}
.submit-wrapper {
  float: right;
  color: @text-black;
  font-weight: bold;
  width: 18%;
  background-color: @green;
  margin-top: 50px;
}


.top-form-wrapper{

}

.heading-register {
  color: @text-white;
  float: left;
  h2 {
    margin-top: 0;
  }
}

.close-register-wrapper {
  width: 30px;
  float: right;
}
 

.agent-details {
  letter-spacing: 2px;
  line-height: 1.8em;
  margin-bottom: 20px;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .form-inputs {
    width: 100%;
  }

  .form-field , .form-field-large {
    width: 100%;
    padding-right: 0;
  }

  .heading-register {
    //display: none;
  }

  .submit-wrapper {
    width: 50%;
    float: left;
  }

  .custom-submit {
    height: 40px;
  }

  .form-slider {
    //padding: @spacing-half;
    transform: translateY(20%);
  }
}

// xs styles
@media (max-width: 767px) {
 
}
