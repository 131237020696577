.form-slider {
	position: fixed;
	top: -100vh;
  width: 100vw;
	background-color: @dark-grey;
	transition: all 1s ease;
  z-index: 150;
}

.form-slider__active {
	top: 0;
}


@media (max-width: 991px)  {
	.form-slider {
		width: 90vw;  
		margin-left: @spacing-mobile;
  	margin-right: @spacing-mobile;
  	padding: @spacing-mobile;
  }
}